@import 'styles/includes.scss';

.SubStartSegmentation {
    &__Grid {
        @extend %container;
        padding-top: 0.8rem;
    }

    &__Header {
        margin-bottom: 40px;
    }

    &__Title {
        @extend %h2;
    }

    &__Items {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -2rem;
    }

    &__Item {
        width: 100%;
        margin-right: 30px;
        margin-bottom: 60px;

        @include media(m) {
            width: calc(50% - 30px);
            margin-right: 30px;
        }

        @include media(l) {
            width: calc(33% - 30px);
            margin-right: 30px;
        }
    }

    &__Segment {
        display: block;
        text-decoration: none;
        color: black;
    }

    &__SegmentTitle {
        @extend %h3;
    }

    &__SegmentTitle {
        @extend %body-copy;
    }

    &__SegmentImage {
        height: 0;
        overflow: hidden;
        padding-top: 55%;
        background: white;
        position: relative;
        margin-bottom: 20px;
    }

    &__SegmentImageComponent {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
}
