$assetPath: '/assets/';
@import './fonts.scss';

$breakpoints: (
    xs: 375px,
    s: 532px,
    m: 768px,
    ml: 1024px,
    ls: 1200px,
    l: 1440px,
    xl: 1680px,
    xxl: 1930px,
);

$maxwidths: (
    xs: 345px,
    s: 500px,
    m: 720px,
    ml: 920px,
    l: 1260px,
    xl: 1440px,
    xxl: 1680px,
);

$maxWidthText: 75rem;

$spacingXs: 4px;
$spacingS: 8px;
$spacingM: 12px;
$spacingL: 16px;
$spacingXl: 24px;

$colorWhite: #ffffff;
$colorWhiteBackground: #FCFCFC;
$colorGray10: #fcfcfc;
$colorGray40: #f6f6f6;
$colorGray100: #e9e9e9;
$colorGray200: #d9d9d9;
$colorGray300: #c4c4c4;
$colorGray500: #7b7b7b;
$colorGray700: #434343;
$colorBlack750: #2a2a2a;
$colorBlack800: #181818;
$colorBlack: #000000;

$colorBlue: #0a77d4;
$colorBlue50: #e7f6ff;
$colorDarkBlue: #052364;
$colorGreen: #008574;
$colorGreen50: #d5f7f4;
$colorOrange: #dd3800;
$colorOrange50: #ffe3df;
$colorOrange800: #dd3800;
$colorPink: #de005d;
$colorPink300: #ffe1ec;
$colorPink600: #de005d;
$colorPink700: #c80058;
$colorPurple: #6d2b9a;
$colorPurple50: #f1e6fc;

$fontBrand: 'Futura', sans-serif;

// Transitions
$duration: 0.3s;
$ease: ease-in-out;
$transition: $duration $ease;
$transitionDelayFast: 0.1s;
$transitionDelayMedium: 0.3s;
$transitionDelaySlow: 0.5s;

@import 'mixins';
@import 'extensions';
